import deepmerge from "@node/deepmerge";

let locales = {};

const modules = import.meta.glob("@o-din-ku/../../config/locales/**/*.yml", { eager: true });

for (const path in modules) {
  locales = deepmerge(locales, modules[path].default);
}

import store from "@o-din-ku/src/ku/store";
import menu from "@o-din-ku/src/ku/menu.json";

import EquipmentsIndex from "@o-din-ku/src/views/ku/equipments/Index.vue";
import EquipmentsForm from "@o-din-ku/src/views/ku/equipments/Form.vue";
import EquipmentsConsumptionChart from "@o-din-ku/src/views/ku/equipments/ConsumptionChart.vue";
import EquipmentsQr from "@o-din-ku/src/views/ku/equipments/Qr.vue";

import EquipmentTypesIndex from "@o-din-ku/src/views/ku/equipment_types/Index.vue";
import EquipmentTypesForm from "@o-din-ku/src/views/ku/equipment_types/Form.vue";
import EquipmentTypesShow from "@o-din-ku/src/views/ku/equipment_types/Show.vue";

import AdditionalEquipmentIndex from "@o-din-ku/src/views/ku/additional_equipment/AdditionalEquipmentIndex.vue";
import AdditionalEquipmentForm from "@o-din-ku/src/views/ku/additional_equipment/AdditionalEquipmentForm.vue";
import AdditionalEquipmentShow from "@o-din-ku/src/views/ku/additional_equipment/AdditionalEquipmentShow.vue";

import EquipmentDatumIndex from "@o-din-ku/src/views/ku/equipment_datum/Index.vue";
import EquipmentDatumForm from "@o-din-ku/src/views/ku/equipment_datum/Form.vue";

export default {
  routes: [
    { path: "/ku/equipments", component: EquipmentsIndex },
    { path: "/ku/equipments/new", component: EquipmentsForm },
    { path: "/ku/equipments/:id/edit", component: EquipmentsForm },
    { path: "/ku/equipments/:id", component: EquipmentsConsumptionChart },
    { path: "/ku/equipments/qr_print", component: EquipmentsQr },
    { path: "/ku/equipment_types", component: EquipmentTypesIndex },
    { path: "/ku/equipment_types/new", component: EquipmentTypesForm },
    { path: "/ku/equipment_types/:id/edit", component: EquipmentTypesForm },
    { path: "/ku/equipment_types/:id", component: EquipmentTypesShow },
    { path: "/ku/additional_equipments", component: AdditionalEquipmentIndex },
    { path: "/ku/additional_equipments/new", component: AdditionalEquipmentForm },
    { path: "/ku/additional_equipments/:id/edit", component: AdditionalEquipmentForm },
    { path: "/ku/additional_equipments/:id", component: AdditionalEquipmentShow },
    { path: "/ku/equipment_data", component: EquipmentDatumIndex },
    { path: "/ku/equipment_data/new", component: EquipmentDatumForm },
    { path: "/ku/equipment_data/:id/edit", component: EquipmentDatumForm },
  ],
  locales,
  store,
  menu: [menu]
};
